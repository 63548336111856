<template>
  <div class="count-home content-block">
    <fm-title title-text="统计">
      <div class="title-s">
        <fm-select absolute filterable :clearable="true" v-model="chooseYear">
          <fm-option v-for="item in years" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
        <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div
              @click="chooseTypeKey = item.key"
              class="fm-tabs-nav-item"
              :class="{'fm-tabs-nav-active': item.key === chooseTypeKey}"
              v-for="item in typeList" :key="item.id">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </fm-title>
    <div class="count-list">
      <a class="count-item" href="javascript:;" @click="goCountPage(config)" v-for="(config, index) in countList" :key="index">
        <div>
        {{config.title}}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import {
  getYears,
  getCountConfigList,
  groupMapBase
} from './lib'

const getTypeList = () => {
  let data = [{
    key: 'all',
    rowGroupKeys: ['base', 'education', 'degree', 'age' ,'level', ['base', 'education', 'degree', 'age', 'level']],
    label: '全部'
  }]
  let types = ['base', 'education', 'degree', 'age', 'level']
  types.forEach(v => {
    data.push({
      key: v,
      label: groupMapBase[v].name,
      rowGroupKeys: [v]
    })
  })
  data.push({
    key: 'allInfo',
    label: '全部信息',
    rowGroupKeys: [['base', 'education', 'degree', 'age', 'level']]
  })
  return data
}

export default {
  data () {
    return {
      years: getYears(),
      chooseYear: getYears()[1],
      typeList: getTypeList(),
      chooseTypeKey: getTypeList()[0].key
    }
  },
  computed: {
    countList () {
      let data = this.typeList.find(v => v.key === this.chooseTypeKey)
      if (data) {
        return getCountConfigList({
          lineGroupKeys: ['glgw_level', 'glgw_base', 'zyjsg_level', 'zyjsg_base', 'zyjsg_zyjs', 'gqjn_level', 'gqjn_base', 'base_ts'],
          rowGroupKeys: data.rowGroupKeys
        })
      } else {
        return []
      }
    }
  },
  methods: {
    goCountPage (config) {
      this.$router.push({
        name: 'count.count',
        params: {
          year: this.chooseYear,
          config
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.count-home {
  margin: 20px;
  height: calc(100% - 40px);
}
.count-list {
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 68px);
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .count-item {
    border-radius: 3px;
    background: #FBBAC4;
    margin: 1rem 1rem 0;
    display: flex;
    align-items: center;
    padding: 10px 1rem;
    width: calc(30% - 2rem);
    color: #fff;
  }
}
.title-s {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>